import ImageLocation from "../img/locations/holly-springs.jpg";

import React from "react";

export default () => (
    <div className="pages section">
        <div className="container">
            <div className="pages-head">
                <h3>{"Holly Springs Fair & Campgrounds"}</h3>
            </div>
            <div className="blog-content">
                <p>
                    {
                        "Nestled in the wooded hillsides of Griswold National Forest, Holly Springs Fair & Campgrounds provides a serene retreat from the stresses of urban life while being the perfect base to enjoy all the area has to offer from hiking to cycling, gigantic firework stores, vintage eateries, new-wave wineries, taco trucks, bait and tackle shops, and much, much more."
                    }
                </p>
                <p>
                    {
                        "Enjoy a secluded experience free from the distractions of urban living. Book now with Wayne @ Henry's Happy Homes!"
                    }
                </p>
                <img
                    style={{ display: "block", float: "none", marginTop: 20 }}
                    src={ImageLocation}
                    alt=""
                />
            </div>
        </div>
    </div>
);
