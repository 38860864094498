import "./PageManageTrip.css";
import * as Backend from "../gameApi";

import React, { useState } from "react";
import useSessionStorage from "react-use/lib/useSessionStorage";
import moment from "moment";
import sha256 from "sha256";

const PERMANENT_FIELDS = ["total", "destination", "route"];

export const BOOKING_INFOS = [
    // missing_teens:
    {
        verify: (lastName, refNum) => {
            // sha256("ledger" + PASSWORD_SALT).toLowerCase()
            const HASH_LAST_NAME =
                "d99af07060269484d36eddcee8c4f53438b56df5310b5c641032988768f467b9";
            // sha256("KABDFUF" + PASSWORD_SALT).toLowerCase()
            const HASH_REF_NUM =
                "65a7eb450e7c72cd0744814906b33a543a6b7efee4588f075c0c553bf0e5caa1";

            return Promise.resolve(
                getSha256String(lastName) === HASH_LAST_NAME &&
                    getSha256String(refNum) === HASH_REF_NUM
            );
        },
        info: () => ({
            destination: "One Way Flight to Twin Falls, Idaho",
            route: "SAN -> TWF",
            when: `${moment().subtract(1, "days").format("LL")} 5:00 PM`,
            name: "Amanda Ledger",
            city: "Twin Falls",
            state: "Idaho",
            country: "United States",
            total: "$270",
        }),
    },
    // gotcha:
    {
        verify: (lastName, refNum) => {
            // sha256("vance" + PASSWORD_SALT).toLowerCase()
            const HASH_LAST_NAME =
                "86bdb37cd198fd4d3d66a2393a0b51cab2d0635bf5b9773f0bba91dbac7e0174";
            if (
                getSha256String(lastName) !== HASH_LAST_NAME ||
                !verifyRefNum(refNum)
            ) {
                return Promise.resolve(false);
            }
            return Backend.verifyRefNum(refNum);
        },
        info: () => ({
            destination: "One Way Flight to Nepal",
            route: "EZE → KTM",
            when: `${moment().add(1, "days").format("LL")} 10:00 AM`,
            name: "Curtis Vance",
            address: "123 Main St.",
            city: "A-Town",
            country: "Argentina",
            total: "$520",
            canCancel: true,
        }),
    },
    // rescue_mission:
    {
        verify: (lastName, refNum) => {
            // sha256("ledger" + PASSWORD_SALT).toLowerCase()
            const HASH_LAST_NAME =
                "d99af07060269484d36eddcee8c4f53438b56df5310b5c641032988768f467b9";
            // sha256("SJTRTPP" + PASSWORD_SALT).toLowerCase()
            const HASH_REF_NUM =
                "47f7efd3524125bbe8ae8fc88040d7ffb885b0eb28b5c4b89eadbcfef5d81e9c";

            return Promise.resolve(
                getSha256String(lastName) === HASH_LAST_NAME &&
                    getSha256String(refNum) === HASH_REF_NUM
            );
        },
        info: () => ({
            destination: "One Way Flight to Portland, Oregon",
            route: "TWF -> PDX",
            when: `${moment().subtract(1, "days").format("LL")} 11:15 PM`,
            name: "Amanda Ledger",
            city: "Portland",
            state: "Oregon",
            country: "United States",
            total: "$310",
        }),
    },
    // phone_5:
    {
        verify: (lastName, refNum) => {
            // sha256("milk" + PASSWORD_SALT).toLowerCase()
            const HASH_LAST_NAME =
                "6c1e6aa8df26e46507760d7528c17663b2d0a9c9a933f3fd090b4762a37b8be1";
            // sha256("ABACCD" + PASSWORD_SALT).toLowerCase()
            const HASH_REF_NUM =
                "8c32dd16c67233c889329f7f28623426bf69387fdfe0274df4fcd5bd02d2d680";

            return (
                getSha256String(lastName) === HASH_LAST_NAME &&
                getSha256String(refNum) === HASH_REF_NUM
            );
        },
        info: () => ({
            destination:
                "Travel to an exotic piece of Queensland history and Rent it for the Summer Today!",
            route: "FLX -> QNS",
            when: `${moment().subtract(1, "days").format("LL")} 11:15 PM`,
            city: "Queensland",
            country: "United States",
            details:
                "7500 sq ft. 4 full bath. 4 bedrooms. 3-car garage. Fully furnished basement. In historic Queensland. Close shops. Great Nightlife. Small raccoon problem. Like it's hardly even a problem. It's really NOTHING PLEASE STOP EMAILING US ON OUR TANNERSLIST ( https://tannerslist.com/ad/2fx56nw1 ) POSTING ABOUT RACCOONS WE DON'T NEED HELP IT IS ABSOLUTELY UNDER CONTROL.",
            total: "$298",
        }),
    },
    // buried-alive-2:
    {
        verify: (lastName, refNum) => {
            // sha256("esposito" + PASSWORD_SALT).toLowerCase()
            const HASH_LAST_NAME =
                "94d0a35e6ff841a0305690c30424000e569031b2c0877d2dff581bb87f302f08";
            // sha256("KDP1J3" + PASSWORD_SALT).toLowerCase()
            const HASH_REF_NUM =
                "faa52883a6a8d89bacc407695c4a85abfdda17564ffb51687ed30fbe45fefe24";

            return (
                getSha256String(lastName) === HASH_LAST_NAME &&
                getSha256String(refNum) === HASH_REF_NUM
            );
        },
        info: () => ({
            destination: "Quaint cabin in the hills.",
            route: "FRG -> CRT",
            when: `${moment().subtract(2, "days").format("LL")} 8:21 PM`,
            city: "Clean Rock",
            country: "United States",
            address: "Cabin 22, Clean Rock Hill",
            details: `1bd room 0 bathroom Cabin in the Clean Rock Hills. Due just north off of October Lake.
                No refunds. Cleaning fee $100. Don't use the Stove, it's broken.
                WARNING: There is an old watermill that occasionally makes a horrible screeching sound a few times during the night.It's not Bigfoot. He's chill.
                While this is an inexpensive stay, it's a hunting cabin that is only rented out in Month-Long Blocks. Please be aware that you'll be responsible for the cabin's condition throughout the Month you booked`,
            total: "$114",
        }),
    },
    // North Pole
    {
        verify: (lastName, refNum) => {
            // sha256("datsyuk" + PASSWORD_SALT).toLowerCase()
            const HASH_LAST_NAME =
                "f249e0394c024fb9ac031b9f2040a4a54fb3e7a75e42f1aa6aa0042586037b50";
            // sha256("3C9I7A" + PASSWORD_SALT).toLowerCase()
            const HASH_REF_NUM =
                "969cfa57c3f36a3652d3ce764725f1a61a1ce8ad16408e295d7d6684ca8c9efa";

            return (
                getSha256String(lastName) === HASH_LAST_NAME &&
                getSha256String(refNum) === HASH_REF_NUM
            );
        },
        info: () => ({
            destination: "Akutan Island, Alaska",
            route: "ARC -> AKT",
            when: `${moment().subtract(2, "days").format("LL")} 6:21 PM`,
            city: "Akutan",
            country: "United States",
            address: "Akutan Island, Alaska",
            details:
                "Private, secured, VIP, helicopter transport. Please present the pilot with reservation details.",
            total: "$4,000",
        }),
    },
    // buried-alive-5
    {
        verify: (lastName, refNum) => {
            // sha256("purpo" + PASSWORD_SALT).toLowerCase()
            const HASH_LAST_NAME =
                "e264cc4bc81cd86f6cfa1d19090215dc9653ed9ee82810a1b993e37faa5cfaf7";
            // sha256("P1JNJ3" + PASSWORD_SALT).toLowerCase()
            const HASH_REF_NUM =
                "6e2d19f214611e5530af06f8e75b29010550d65ab17903827225f12f4db55298";

            return (
                getSha256String(lastName) === HASH_LAST_NAME &&
                getSha256String(refNum) === HASH_REF_NUM
            );
        },
        info: () => ({
            destination: "Hopeful Hills Cabins",
            when: `${moment().subtract(0, "days").format("LL")} 10:00 AM`,
            city: "Harmony Falls",
            country: "United States",
            address: "Lodge #07, Harmony Falls",
            details: "One-Way Ticket",
            total: "$167.78",
        }),
    },
    // paparazzi-5
    {
        verify: (lastName, refNum) => {
            // sha256("popova" + PASSWORD_SALT).toLowerCase()
            const HASH_LAST_NAME =
                "bf79f704d8f56e62119cdbb29e545b5f23be088f84fcd808a19b6776008a1dfc";
            // sha256("CJ41jfk" + PASSWORD_SALT).toLowerCase()
            const HASH_REF_NUM =
                "eeeb21e70bdf61c20d2ba6304badfef7bc97f119afb2aeee0f5a7f3ce5567caa";

            return (
                getSha256String(lastName) === HASH_LAST_NAME &&
                getSha256String(refNum) === HASH_REF_NUM
            );
        },
        info: () => ({
            destination: "Malta",
            route: "DTX <-> MLA",
            when: `${moment().subtract(36, "days").format("LL")} 5:45 AM`,
            city: "Luqa",
            country: "Malta",
            duration: "10 hours",
            total: "$1436",
            "Destination Loyalty Club": "Gold Member ⭐",
            "Destination Loyalty Points": "2600 pts.",
            "Destination Loyalty Rewards":
                "Priority boarding, extra baggage allowance, access to club lounge",
            "Destination Loyalty Frequency": "You visit Malta every 2 months",
        }),
    },
];

function getSha256String(string) {
    const PASSWORD_SALT = "cTN#Q4sAxPTRSEtFgf";
    return sha256(string.trim().toLowerCase() + PASSWORD_SALT);
}

export function verifyRefNum(refNum) {
    if (typeof refNum !== "string") {
        return false;
    }

    refNum = refNum.trim().toUpperCase();

    if (refNum.match(/[A-Z0-9]{6}/) === null) {
        return false;
    }

    let checksum = 0;
    for (let i = 0; i < refNum.length - 1; i += 1) {
        checksum += refNum.charCodeAt(i);
    }

    return (checksum % 26) + 65 === refNum.charCodeAt(refNum.length - 1);
}

export default function PageManageTrip() {
    const [refNum, setRefNum] = useSessionStorage("ref_nu", null);
    const [bookingInfo, setBookingInfo] = useSessionStorage(
        "booking_info",
        null
    );
    const [loginError, setLoginError] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = (inputRefNum, lastName) => {
        setLoading(true);
        setLoginError(false);
        Promise.all(
            BOOKING_INFOS.map((story) => story.verify(lastName, inputRefNum))
        )
            .then((bookingInformations) => {
                const storyIdx = bookingInformations.findIndex(
                    (isVerified) => isVerified
                );
                if (storyIdx !== -1) {
                    setBookingInfo(BOOKING_INFOS[storyIdx].info());
                    setRefNum(inputRefNum);
                } else {
                    setLoginError(true);
                }
            })
            .catch(() => {
                setLoginError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    if (!refNum) {
        return (
            <LoginForm
                onSubmit={handleSubmit}
                error={loginError}
                loading={loading}
            />
        );
    }

    const handleLogout = () => {
        setBookingInfo(null);
        setRefNum(null);
        setLoginError(false);
    };

    return (
        <ManageTrip
            refNum={refNum}
            bookingInfo={bookingInfo}
            onLogout={handleLogout}
        />
    );
}

function ManageTrip({ refNum, bookingInfo, onLogout }) {
    return (
        <div className="container">
            <div className="content">
                <BookingInfo refNum={refNum} bookingInfo={bookingInfo} />
                <div className="ManageTrip-actions container">
                    {bookingInfo?.canCancel ? (
                        <CancelTripButton refNum={refNum} />
                    ) : null}
                    <div className="button-default logout" onClick={onLogout}>
                        {"Log Out"}
                    </div>
                </div>
            </div>
        </div>
    );
}

function CancelTripButton({ refNum }) {
    function handleCancel() {
        Backend.cancelTrip(refNum)
            .then(() => {
                // eslint-disable-next-line no-alert
                alert(
                    "Booking canceled successfully. This page will update in the next 48 hours."
                );
            })
            .catch(() => {
                // eslint-disable-next-line no-alert
                alert("Failed to cancel the booking. Please try again.");
            });
    }

    return (
        <button
            type="submit"
            className="button-default button-fullwidth cancel"
            onClick={handleCancel}
        >
            {"Cancel Booking"}
        </button>
    );
}

function BookingInfo({ refNum, bookingInfo }) {
    const { destination, route, total } = bookingInfo;

    return (
        <div className="pages section booking">
            <div className="container">
                <div className="pages-head">
                    <h3>{"Booking"}</h3>
                </div>
                <div className="content-wrap">
                    <div className="row row-border">
                        <div className="col s12">
                            <div className="content">
                                <h3>{destination}</h3>
                                <span>{route}</span>
                                <p>
                                    <b>{"Reference number: "}</b>
                                    {refNum.toUpperCase()}
                                </p>
                                <BookingInfoFields bookingInfo={bookingInfo} />
                            </div>
                        </div>
                    </div>
                    <h6>
                        {"Total : "}
                        {total}
                    </h6>
                </div>
            </div>
        </div>
    );
}

const BookingInfoFields = ({ bookingInfo }) => {
    const bookingInfoFields = Object.keys(bookingInfo).filter(
        (key) => !PERMANENT_FIELDS.includes(key)
    );
    const bookingInfoFieldsToRender = bookingInfoFields.map((key) => {
        const field = bookingInfo[key];
        return (
            <p key={key}>
                <b>{`${capitalizeFirstLetter(key)}: `}</b>
                {field}
            </p>
        );
    });
    return <>{bookingInfoFieldsToRender}</>;
};

function LoginForm({ onSubmit, error, loading }) {
    const [refNum, setRefNum] = useState("");
    const [lastName, setLastName] = useState("");

    function handleSubmit(evt) {
        evt.preventDefault();
        if (onSubmit && refNum && lastName) {
            onSubmit(refNum, lastName);
        }
    }

    return (
        <div className="login-register-wrap-home">
            <div className="container">
                <div className="content">
                    <h6>
                        {"To manage your booking, enter the following details."}
                    </h6>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            placeholder="Booking Reference Number"
                            required
                            value={refNum}
                            onChange={(evt) => setRefNum(evt.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="Last Name"
                            required
                            value={lastName}
                            onChange={(evt) => setLastName(evt.target.value)}
                        />
                        <button type="submit" className="button-default">
                            {loading ? (
                                <IconLoading className="spin" size={30} />
                            ) : (
                                "Manage My Trip"
                            )}
                        </button>

                        {error ? (
                            <p className="error">
                                {
                                    "We cannot find your booking. Please check your details and try again."
                                }
                            </p>
                        ) : null}
                    </form>
                </div>
            </div>
        </div>
    );
}

const IconLoading = ({ size, className }) => (
    <svg
        width={size}
        height={size}
        className={className}
        version="1.1"
        viewBox="0 0 16 16"
        x="0px"
        y="0px"
        fill="white"
    >
        <path d="M15 9h-2c-0.553 0-1-0.447-1-1s0.447-1 1-1h2c0.553 0 1 0.447 1 1s-0.447 1-1 1zM12.242 5.172c-0.391 0.391-1.023 0.391-1.414 0s-0.391-1.023 0-1.414l1.414-1.414c0.391-0.391 1.023-0.391 1.414 0s0.391 1.023 0 1.414l-1.414 1.414zM8 16c-0.553 0-1-0.447-1-1v-2c0-0.553 0.447-1 1-1s1 0.447 1 1v2c0 0.553-0.447 1-1 1zM8 4c-0.553 0-1-0.447-1-1v-2c0-0.553 0.447-1 1-1s1 0.447 1 1v2c0 0.553-0.447 1-1 1zM3.758 13.656c-0.391 0.391-1.023 0.391-1.414 0s-0.391-1.023 0-1.414l1.414-1.414c0.391-0.391 1.023-0.391 1.414 0s0.391 1.023 0 1.414l-1.414 1.414zM3.758 5.172l-1.414-1.414c-0.391-0.391-0.391-1.023 0-1.414s1.023-0.391 1.414 0l1.414 1.414c0.391 0.391 0.391 1.023 0 1.414s-1.023 0.391-1.414 0zM4 8c0 0.553-0.447 1-1 1h-2c-0.553 0-1-0.447-1-1s0.447-1 1-1h2c0.553 0 1 0.447 1 1zM12.242 10.828l1.414 1.414c0.391 0.391 0.391 1.024 0 1.414s-1.023 0.391-1.414 0l-1.414-1.414c-0.391-0.391-0.391-1.023 0-1.414s1.023-0.391 1.414 0z" />
    </svg>
);

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
