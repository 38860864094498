import "./css/materialize.css";
import "./font-awesome/css/font-awesome.min.css";
import "./css/normalize.css";
import "./css/owl.carousel.css";
import "./css/owl.theme.css";
import "./css/owl.transitions.css";
import "./css/fakeLoader.css";
import "./css/style.css";

import NavbarTop from "./components/NavbarTop";
import Footer from "./components/Footer";
import SideNav from "./components/SideNav";
import PageHome from "./components/PageHome";
import PageContact from "./components/PageContact";
import PageAbout from "./components/PageAbout";
import PageLocationHollySprings from "./components/PageLocationHollySprings";
import PageManageTrip from "./components/PageManageTrip";

import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

export default function TravelAgencyWebsite() {
    return (
        <Router>
            <TravelAgencyWebsiteContent />
        </Router>
    );
}

export const TravelAgencyWebsiteContent = () => (
    <>
        <NavbarTop />
        <SideNav />
        <Switch>
            <Route path="/" exact>
                <PageHome />
            </Route>
            <Route path="/contact" exact>
                <PageContact />
            </Route>
            <Route path="/about-us" exact>
                <PageAbout />
            </Route>
            <Route path="/manage-trip" exact>
                <PageManageTrip />
            </Route>

            {/* Buried Alive 4 */}
            <Route path="/locations/holly-springs-fair-and-campgrounds" exact>
                <PageLocationHollySprings />
            </Route>
        </Switch>
        <Footer />
    </>
);
