import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default () => {
    useEffect(() => {
        if (window.$) {
            window.$(".side-nav-left").sideNav({
                edge: "left",
                closeOnClick: true,
            });
        }
    }, []);

    return (
        <div className="navbar-top">
            <div className="side-nav-panel-left">
                <a
                    href="/"
                    data-activates="slide-out-left"
                    className="side-nav-left"
                >
                    <i className="fa fa-bars" />
                </a>
            </div>

            <div className="site-brand">
                <Link to="/">
                    <h1>{"QUACK"}</h1>
                </Link>
            </div>

            <div className="side-nav-panel-right">
                <Link to="/contact" className="side-nav-right">
                    <i className="fa fa-envelope" />
                </Link>
            </div>
        </div>
    );
};
