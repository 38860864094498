import React from "react";
import { Link } from "react-router-dom";

export default () => (
    <div className="side-nav-panel-left">
        <ul id="slide-out-left" className="side-nav side-nav-panel collapsible">
            <li className="li-top">
                <Link to="/">
                    <i className="fa fa-home" />
                    {"Home"}
                </Link>
            </li>
            <li>
                <Link to="/about-us">
                    <i className="fa fa-user" />
                    {"About Us"}
                </Link>
            </li>
            <li>
                <Link to="/contact">
                    <i className="fa fa-envelope-o" />
                    {"Contact Us"}
                </Link>
            </li>
            <li>
                <Link to="/manage-trip">
                    <i className="fa fa-sign-in" />
                    {"Manage My Trip"}
                </Link>
            </li>
        </ul>
    </div>
);
