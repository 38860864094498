import React, { useEffect } from "react";

import ImageMountainSkyTrips from "../img/tours-travel1.jpg";
import ImageRelaxOnTheBeach from "../img/tours-travel2.jpg";
import ImageVisitHistoricMuseum from "../img/tours-travel3.jpg";
import ImageSlide1 from "../img/slide1.jpg";
import ImageSlide2 from "../img/slide2.jpg";
import ImageSlide3 from "../img/slide3.jpg";

const TOURS = [
    {
        title: "Mountain Sky Trips",
        image: ImageMountainSkyTrips,
        price: "$1,290",
        description:
            "Spend your days exploring the vast and unspoiled wilderness of Montana hiking or on horseback.",
    },
    {
        title: "Relax on the Beach",
        image: ImageRelaxOnTheBeach,
        price: "$740",
        description:
            "Are you looking for the perfect beachfront getaway? Book this ultimate Gulf Coast getaway today!",
    },
    {
        title: "Visit the Historic Museum",
        image: ImageVisitHistoricMuseum,
        price: "$2,290",
        description:
            "Our most prestige package! A journey into the history of art.",
    },
];

export default () => (
    <>
        <Slider />
        <Popular />
        <Promo />
        <ToursTravel />
    </>
);

function ToursTravel() {
    return (
        <div className="tours-travel">
            <div className="container">
                <div className="section-head">
                    <h4>{"TOURS AND TRAVEL"}</h4>
                    <div className="underline" />
                </div>
                {TOURS.map((tour, i) => (
                    <div key={i} className="content-wrap">
                        <div className="row">
                            <div className="col s6">
                                <div className="content-img">
                                    <img src={tour.image} alt="" />
                                    <div className="overlay">
                                        <span>{tour.price}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col s6">
                                <div className="content">
                                    <h3>{tour.title}</h3>
                                    <ul>
                                        <li>
                                            <i className="fa fa-star" />
                                        </li>
                                        <li>
                                            <i className="fa fa-star" />
                                        </li>
                                        <li>
                                            <i className="fa fa-star" />
                                        </li>
                                        <li>
                                            <i className="fa fa-star" />
                                        </li>
                                        <li>
                                            <i className="fa fa-star" />
                                        </li>
                                    </ul>
                                    <a
                                        href="/contact"
                                        className="button-default"
                                    >
                                        {"Book Now"}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

function Promo() {
    return (
        <div className="section promo">
            <div className="container">
                <h4>{"Your Trip, Your Adventure"}</h4>
                <p>
                    {
                        "With Quack Travel, you have the freedom to personalize your experience."
                    }
                </p>
            </div>
        </div>
    );
}

function Popular() {
    return (
        <div className="section popular">
            <div className="container">
                <div className="section-head">
                    <h4>{"Popular"}</h4>
                    <div className="underline" />
                </div>
                {TOURS.map((tour, i) => (
                    <div key={i} className="content">
                        <div className="content-img">
                            <img src={tour.image} alt={tour.title} />
                            <div className="overlay">
                                <span>{tour.price}</span>
                            </div>
                        </div>
                        <div className="text-head">
                            <h3>
                                <a href="/">{tour.title}</a>
                            </h3>
                            <ul>
                                <Star />
                                <Star />
                                <Star />
                                <Star />
                                <Star />
                            </ul>
                        </div>
                        <div className="text">
                            <p>{tour.description}</p>
                            <a href="/contact" className="button-default">
                                {"Book Now"}
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

function Slider() {
    useEffect(() => {
        if (window?.$) {
            window.$(".slider").slider({ full_width: true });
        }
    }, []);

    return (
        <div className="slider">
            <ul className="slides">
                <li>
                    <img src={ImageSlide1} alt="" />
                    <div className="caption slider-content center-align">
                        <div className="container">
                            <h2>{"Best Tours & Travel"}</h2>
                            <h4>
                                {
                                    "A world of travel and tours packages, all in one place!"
                                }
                            </h4>
                        </div>
                    </div>
                </li>
                <li>
                    <img src={ImageSlide2} alt="" />
                    <div className="caption slider-content center-align">
                        <div className="container">
                            <h2>{"Enjoyable Trips"}</h2>
                            <h4>{"We offer the best experiences"}</h4>
                        </div>
                    </div>
                </li>
                <li>
                    <img src={ImageSlide3} alt="" />
                    <div className="caption slider-content center-align">
                        <div className="container">
                            <h2>{"Save Money"}</h2>
                            <h4>{"Build the perfect trip for your budget"}</h4>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    );
}

function Star() {
    return (
        <li>
            <i className="fa fa-star" />
        </li>
    );
}
