import React from "react";

export default () => (
    <div className="footer">
        <div className="container">
            <div className="about-us-foot">
                <h6>{"QUACK TRAVEL"}</h6>
                <p>
                    {
                        "The content and information on this page are dynamic and change from time to time, so the information may no longer be relevant at this time. Do not treat the information as absolute or binding. The most accurate and up-to-date information about your order will only appear on the product order page."
                    }
                </p>
            </div>
            <div className="copyright">
                <span>{"© 2020 All Right Reserved"}</span>
            </div>
        </div>
    </div>
);
