import urljoin from "url-join";
import axios from "axios";
import axiosRetry from "axios-retry";

const PROD_URL = "https://peekaph.one/";
const HOSTNAME_TO_BASE_URL = {
    // staging
    // "peek-a-phone-dashboard-staging.web.app":
    // 	"https://staging-dot-peek-a-phone.appspot.com/",
    //
    // production
    "peek-a-phone-website-travel.web.app": PROD_URL,
    "peek-a-phone-website-travel.firebaseapp.com": PROD_URL,
    "quacktravel.com": PROD_URL,
    "www.quacktravel.com": PROD_URL,
};
if (process.env.NODE_ENV !== "production") {
    HOSTNAME_TO_BASE_URL[
        window.location.hostname
    ] = `http://${window.location.hostname}:9080/`;
}
const BASE_URL = HOSTNAME_TO_BASE_URL[window.location.hostname] || PROD_URL;

const API_URL = urljoin(BASE_URL, "/api/websites/travel-agency");
const REQUEST_TIMEOUT_MS = 10 * 1000;

const client = axios.create();
axiosRetry(client, {
    retries: 5,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: axiosRetry.isNetworkOrIdempotentRequestError,
    shouldResetTimeout: true,
});

export function verifyRefNum(refNum) {
    return apiCall(`booking/${refNum}`, "get");
}

export function cancelTrip(refNum) {
    return apiCall(`booking/${refNum}/cancel`, "put");
}

function apiCall(url, method, options) {
    const { data } = options || {};

    return axios.request({
        url: urljoin(API_URL, url),
        method,
        timeout: REQUEST_TIMEOUT_MS,
        withCredentials: true,
        data,
    });
}
