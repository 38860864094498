import React from "react";
import ImageAbout from "../img/about.jpg";

export default () => (
    <div className="pages section">
        <div className="container">
            <div className="pages-head">
                <h3>{"ABOUT US"}</h3>
            </div>
            <div className="about-us">
                <img src={ImageAbout} alt="" />
                <p>
                    {
                        "Quack Travel is a leading travel chain in Argentina, with many branches throughout the country. In addition, Quack Travel has a call center and a website that together provide a variety of options for customers to contact and order tourism products in the most appropriate way for them. Quack Travel has over 400 professional and skilled travel consultants who sit in the various branches and call centers and are ready to give the best answer to every customer. Quack Travel provides a solution for both private and business customers, groups and organizations (such as workers' committees, schools, etc.)"
                    }
                </p>
            </div>
        </div>
    </div>
);
